import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import "../components/layout.css"

const ErrorPage = () => {
  return (
    <Layout>
      <section className="error-page">
        <div className="page-center">
          <span>404</span>
          <h3>The page you are looking for can’t be found.</h3>
          <Link to="/" className="btn">
            back home
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default ErrorPage
